[data-page="onboarding"] {

  > header {
    margin-bottom: var(--spacing-300);
    padding: var(--spacing-50) var(--spacing-200);
  }

  .content-wrapper {
    display: flex;
    gap: 1rem;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    > aside {
      flex-basis: 20rem;
      flex-shrink: 0;

      display: flex;
      flex-direction: column;
      gap: 1rem;

      [data-component="ChangeLoanConditions"] {
        flex: 0;
      }

      .recommendation-container {

        flex: 0;

        > h2 {
          @apply text-400 leading-[95%];
          font-weight: 700;
        }
      }

      @media (max-width: 1024px) {
        flex-direction: row;
        flex-basis: unset;
        align-items: stretch;

        > [data-component="ChangeLoanConditions"] {
          flex: 1;
        }

        > .recommendation-container {
          flex: 2;
        }

        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
    }

    .products, .sorry-blocks {

      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));

      gap: 1rem;

      .still-possible-card {

        order: 0;
        padding: var(--spacing-100) var(--spacing-200);

        > h2 {
          @apply text-500 leading-[95%];
          font-weight: 700;
          margin-bottom: 1rem;
        }
      }
    }

    .sorry-blocks {

      @media (min-width: 1024px) {

        display: flex;
        flex-direction: column;
      }
    }
  }
}

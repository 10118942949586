@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@monto/tailwindcss/default.css";

@import 'variables.css';
@import 'base/buttons.css';
@import 'base/form.css';
@import 'base/tabs.css';
@import 'base/typography.css';
@import 'base/tag.css';

@import 'components/pincode';
@import 'components/dialog';
@import 'components/guarantors-list';
@import 'components/guarantor-section';
@import 'components/invoice-list';
@import 'components/select-provider';
@import 'components/loan-section';
@import 'components/signature';
@import 'components/create-invoice-form';
@import 'components/card-offer';
@import 'components/recommendation-box';
@import 'components/onboarding-test';
@import 'components/change-loan-conditions';

@import 'pages/connect-erp-index';
@import 'pages/onboarding';

/* temporary to remove bug in CompanyLayout component, move later */
&[data-has-blur="true"] {
  filter: blur(5px);
}
